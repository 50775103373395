const HTML_TAG_CLASS = 'text-info';
const HTML_ATTRIBUTE_NAME_CLASS = 'text-secondary';
const HTML_ATTRIBUTE_VALUE_CLASS = 'text-primary-alt';

/**
 * Make a really simple (and not-working-all-the-time) HTML syntax highlighter...
 * It's just to avoid loading a third-party library...
 */
export function hightlightHTML(line: string): string {
  let html = line || ' ';
  html = html
    // Replace the "<" and ">" by HTML entities, so they will not be matched in next regexps...
    .replace(/</g, '&lt;')
    .replace(/>/g, '&gt;')
    // Find tag attributes.
    .replace(
      /[\w_-]+="([\w\s_#:;-]+)?"/g,
      (m) =>
        `<span class="${HTML_ATTRIBUTE_NAME_CLASS}">${
          m.split('=')[0]
        }=</span><span class="${HTML_ATTRIBUTE_VALUE_CLASS}">${m.split('=')[1]}</span>`,
    )
    // Find tag names.
    .replace(/(&lt;\/?[\w-]+(&gt;)?)|(&gt;)/g, (m) => `<span class="${HTML_TAG_CLASS}">${m}</span>`);
  return html;
}

export function copyToClipboard(content: string, onCopy?: () => void) {
  if (navigator.clipboard) {
    navigator.clipboard.writeText(content);
    onCopy?.();
  } else {
    const ta = document.createElement('textarea');
    // Avoid scrolling to bottom
    ta.style.top = '0';
    ta.style.left = '0';
    ta.style.position = 'fixed';
    ta.value = content;
    document.body.append(ta);
    ta.focus();
    ta.select();
    document.execCommand('copy') && onCopy?.();
    document.body.removeChild(ta);
  }
}
