import React, { useEffect, useState } from 'react';
import { WidgetConfiguration } from '@sg-widgets/shared-core';
import { getAuthorizationToken } from '../../../common/sgwt-widgets-utils';
import { SgmDesignLabConfiguration, SgwtPlaygroundViewerProps } from '../sgwt-playground-viewer.types';
import { copyToClipboard, hightlightHTML } from './playground.utils';

interface PlaygroundViewerProps extends Omit<SgwtPlaygroundViewerProps, 'onReady'> {
  sgmDesignLabConfiguration: SgmDesignLabConfiguration;
  widgetConfiguration: WidgetConfiguration;
}

const ErrorMessage = ({ previewHeight, error }: { previewHeight?: string; error: string }) => (
  <div className="w-100" style={{ height: previewHeight }}>
    <div className="p-4 text-secondary text-center">
      {error === 'not-found' ? (
        <div>
          <i className="icon icon-xl">not_listed_location</i>
          <h3>Playground not found</h3>
          <p>We cannot display the snippet right now.</p>
        </div>
      ) : (
        <div>
          <i className="icon icon-xl text-danger">whatshot</i>
          <h3>Our server is on fire!</h3>
          <p>We cannot display the snippet right now.</p>
        </div>
      )}
    </div>
  </div>
);

interface CodeViewerProps extends PlaygroundViewerProps {
  code: string;
  errorOnLoading: string | null;
}

const CodeViewer = (props: CodeViewerProps) => {
  const [contentCopied, setContentCopied] = useState(false);
  const [codePanelExpanded, setCodePanelExpanded] = useState(false);
  const lines = props.code ? props.code.split('\n') : [];

  const copyCodeToClipboard = () => {
    const copied = () => {
      setContentCopied(true);
      setTimeout(() => {
        setContentCopied(false);
      }, 3000);
    };

    copyToClipboard(props.code, copied);
  };

  const expandCodePanel = () => setCodePanelExpanded(!codePanelExpanded);

  return (
    <div className={`border-top bg-alt-lvl1 ${props.codeClass ?? ''}`}>
      <div className="mx-3 py-2 border-bottom d-flex align-items-center justify-content-between">
        <div>
          <button className="btn btn-flat-primary-alt" onClick={copyCodeToClipboard}>
            <i className="icon">content_copy</i>
          </button>
          {contentCopied && <i className="icon text-success">check</i>}
          <button className="btn btn-icon-start btn-icon-text btn-flat-primary-alt" onClick={expandCodePanel}>
            <i className="icon">{codePanelExpanded ? 'unfold_less' : 'unfold_more'}</i>
            {codePanelExpanded ? 'Collapse' : 'Expand'}
          </button>
        </div>
        <a
          className="btn btn-icon-end btn-text-icon btn-flat-primary-alt"
          href={`${props.sgmDesignLabConfiguration.editor}/${props.playgroundId}`}
          target="_blank"
        >
          Open Playground
          <i className="icon">open_in_new</i>
        </a>
      </div>
      <div
        className="mx-3 py-3 text-secondary"
        style={{
          overflow: 'auto',
          height: codePanelExpanded ? 'auto' : props.codeHeight,
        }}
      >
        {props.errorOnLoading ? (
          <ErrorMessage error={props.errorOnLoading} previewHeight={props.previewHeight} />
        ) : (
          <div className="d-flex">
            <pre className="mb-0 text-secondary text-right text-end" style={{ width: '30px', fontSize: '14px' }}>
              {Array(lines.length)
                .fill('')
                .map((_, index) => (
                  <div>{index + 1}</div>
                ))}
            </pre>
            <pre className="mb-0 ps-3 pl-3 text-primary-alt" style={{ fontSize: '14px' }}>
              {lines.map((line, index) => (
                <div key={`line-${index}`} dangerouslySetInnerHTML={{ __html: hightlightHTML(line) }} />
              ))}
            </pre>
          </div>
        )}
      </div>
    </div>
  );
};

export default function PlaygroundViewer(props: PlaygroundViewerProps) {
  const [errorOnLoading, setErrorOnLoading] = useState<string | null>(null);
  const [code, setCode] = useState('');

  const needToFetchCode = props.showCode || !props.useIframe;

  useEffect(() => {
    if (needToFetchCode) {
      const token = getAuthorizationToken(props.widgetConfiguration);
      if (token) {
        fetch(`${props.sgmDesignLabConfiguration.api}/${props.playgroundId}`, {
          headers: {
            Authorization: token,
          },
        })
          .then((data) => data.json())
          .then((response) => {
            if (response.statusCode < 200 || response.statusCode >= 400) {
              setErrorOnLoading(response.statusCode === 404 ? 'not-found' : 'server-error');
            } else {
              setCode(response.code);
            }
          })
          .catch((error) => {
            console.error('Error fetching the playground code', error);
            setErrorOnLoading('server-error');
          });
      } else {
        console.warn(`No token found to fetch the playground ${props.playgroundId} code`);
      }
    }
  }, [props.playgroundId, props.sgmDesignLabConfiguration.api, needToFetchCode, props.widgetConfiguration]);

  return (
    <div className={props.containerClass ?? ''}>
      {props.useIframe ? (
        <iframe
          src={`${props.sgmDesignLabConfiguration.iframe}?id=${props.playgroundId}`}
          frameBorder="0"
          title="playground preview"
          className={`w-100 d-block ${props.previewClass ?? ''}`}
          style={{ height: props.previewHeight }}
        />
      ) : errorOnLoading ? (
        <ErrorMessage error={errorOnLoading} previewHeight={props.previewHeight} />
      ) : (
        <div
          className={`w-100 ${props.previewClass ?? ''}`}
          style={{ height: props.previewHeight, overflow: 'scroll' }}
          dangerouslySetInnerHTML={{ __html: code }}
        />
      )}
      {props.showCode && <CodeViewer {...props} code={code} errorOnLoading={errorOnLoading} />}
    </div>
  );
}
