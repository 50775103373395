import { WidgetEnvironment } from '../../common/sgwt-widgets-utils';

export interface SgwtPlaygroundViewerProps {
  codeClass?: string;
  codeHeight?: string;
  containerClass?: string;
  playgroundId: string;
  previewClass?: string;
  previewHeight?: string;
  showCode?: boolean;
  useIframe?: boolean;
  // Events
  onReady: () => void;
}

export const DEFAULT_IFRAME_HEIGHT = '300px';
export const DEFAULT_PREVIEW_HEIGHT = '100%';
export const DEFAULT_CODE_HEIGHT = '200px';

export interface SgmDesignLabConfiguration {
  api: string;
  editor: string;
  iframe: string;
}

export const SgmDesignLab: Record<WidgetEnvironment, SgmDesignLabConfiguration> = {
  development: {
    api: 'https://sgm-designlab-dev.sgmarkets.world.socgen/api/v1/sgbs-playground',
    editor: 'https://sgm-designlab-dev.sgmarkets.com/editor',
    iframe: 'https://shared.sgmarkets.com/assets/playground/viewer-dev.html',
  },
  homologation: {
    api: 'https://sgm-designlab-uat.sgmarkets.world.socgen/api/v1/sgbs-playground',
    editor: 'https://sgm-designlab-uat.sgmarkets.com/editor',
    iframe: 'https://shared.sgmarkets.com/assets/playground/viewer-uat.html',
  },
  production: {
    api: 'https://sgm-designlab.sgmarkets.world.socgen/api/v1/sgbs-playground',
    editor: 'https://designlab.sgmarkets.com/editor',
    iframe: 'https://shared.sgmarkets.com/assets/playground/viewer-standard.html',
  },
};
